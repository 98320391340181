//import ShortcutPanel from "rmc-calendar/lib/calendar/ShortcutPanel";


const defaultState = {
    join_ok: false,//是否登录成功
    openid:'',
    userid: '',//账号信息的Id
    nickname: '',//用户昵称
    sex: '',//用户性别
    headimg: '',//用户头像
    selectedTab: 'redTab',
    hidden: false,
    fullScreen: true,
    courseIndex:0,
    MenuId:0,
    CourseList:{CourseId:0}  
}
export default (state = defaultState, action) => {
    if (action.type === 'change_div_color') {
        const newState = JSON.parse(JSON.stringify(state));
        newState.selectedTab = action.selectedTab;
        return newState;
    }
    return state;
}