
import axios from "axios";
import qs from 'qs';
import WeixinJSBridge from 'weixin-js-sdk';
import {ImageUrl,InterfaceUrl} from '../common/PublicUrl.js';
export function isWeixinBrowser() {
  var ua = window.navigator.userAgent.toLowerCase();
  console.log(ua);
  if (ua.match(/MicroMessenger/i) == 'micromessenger') {
    return true;
  } else {
    return false;
  }
}
export function isOpenId() {
  function GetParameter(name) {
    var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
    var r = window.location.search.substr(1).match(reg);
    if (r != null) return unescape(r[2]); return null;
  }
  // sessionStorage.setItem("openid","oawZ-6SYHUOBuXAzRwgBcXyVLMBA")
  // sessionStorage.setItem("userid","74")
  // sessionStorage.setItem("wxtype","4")
  let openid = sessionStorage.getItem("openid")
  let userid = sessionStorage.getItem("userid")
  let wxtype= sessionStorage.getItem("wxtype")
  if (openid == null || openid == "") {
    openid = GetParameter("openid");
    userid = GetParameter("userid");
    wxtype = GetParameter("wxtype");
    if (openid != null && openid != "") {
      sessionStorage.setItem("openid", openid)
      sessionStorage.setItem("userid", userid)
      sessionStorage.setItem("wxtype", wxtype)
      var nurl = window.location.href;
      nurl = delUrlParams(nurl, "userid");
      nurl = delUrlParams(nurl, "openid");
      nurl = delUrlParams(nurl, "wxtype");
      window.location.href = nurl;
      console.log("nurl:"+nurl)
    }
  }
  console.log("openid" + openid + ",userid:" + userid+",wxtype:"+wxtype);
  var url = window.location.href;
  url = url.replace(InterfaceUrl, "")
  console.log("url:"+url)
  // if (url.indexOf("?") >= 0) {
  //   url = url + "&userid=" + userid
  // } else {
  //   url = url + "?userid=" + userid
  // }
  if (openid == null || openid == "") {
    window.location.href = InterfaceUrl+"SharePiano/Interface/getUserInfo.aspx?url=" + url
  }
}
/**
  * 删除URL中的指定参数
   * @param {*} url 
  * @param {*} name 
   */
function delUrlParams(url, name) {
  //根据#号拆分
  let poundArr = url.split('#')
  //？拆分
  let questionArr = []
  if (poundArr) {
    //把#接上
    poundArr.forEach((element, index) => {
      if (index > 0) {
        element = '#' + element
      }

      let tempArr = element.split('?')
      if (!tempArr) {
        return true
      }
      tempArr.forEach((item, idx) => {
        //保留问号
        if (idx > 0) {
          item = '?' + item
        }
        questionArr.push(item)
      })
    });
  } else {
    questionArr = url.split('?')
    if (questionArr) {
      questionArr.forEach((item, idx) => {
        if (idx > 0) {
          item = '?' + item
        }
      })
    }
  }

  if (!questionArr) {
    return url
  }

  //&符号的处理
  let andArr = []
  questionArr.forEach((item, index) => {
    let andIdx = item.indexOf('&')
    if (andIdx <= -1) {
      andArr.push(item)
      return true
    }

    let tempAndArr = item.split('&')
    tempAndArr.forEach((ele, idx) => {
      if (idx > 0) {
        ele = '&' + ele
      }
      andArr.push(ele)
    })
  })


  let newUrl = ''
  andArr.forEach(item => {
    let nameIndex = item.indexOf(name + '=')
    //不拼接要删除的参数
    if (nameIndex > -1) {
      //保留第一个问号
      let questionIdx = item.indexOf('?')
      if (questionIdx == 0) {
        newUrl += '?'
      }
      return true
    }
    newUrl += item
  })

  return newUrl.replace(/\?\&/g, "?")
}
export function isback() {
  // document.addEventListener('WeixinJSBridgeReady', function onBridgeReady() {
  //   WeixinJSBridge.call('hideToolbar');
  //   WeixinJSBridge.call('hideOptionMenu');
  //   });
   // pushHistory();
  // window.addEventListener("popstate", function (e) {
  //   this.props.history.push({ pathname: '/' })
  // }, false);
  // function pushHistory() {
  //   var state = {}
  //   console.log(window.history)
  //   state = {
  //     title: "title",
  //     url: "#"
  //   };
  //   window.history.pushState(state, "title", "#");
  // }
}
