import wx from 'weixin-js-sdk';
import { Toast } from 'antd-mobile';
import axios from "axios";
import { ImageUrl, InterfaceUrl } from '../common/PublicUrl.js';
import qs from 'qs';
import store from '../../store';
export function WxShare(shareUrl) {
  var requestUrl = shareUrl
  var wxtype = sessionStorage.getItem("wxtype")
  console.log(requestUrl)
  let param = {}
  param["url"] = requestUrl
  param["wxtype"] = wxtype
  param = qs.stringify(param)
  axios({
    method: 'post',
    url: InterfaceUrl + 'SharePiano/Interface/getSignature.aspx',
    data: param
  }).then(function (response) {
    var userid = sessionStorage.getItem("userid")
    shareUrl = delUrlParams(shareUrl, "userid");
    if (shareUrl.indexOf("?") >= 0) {
      shareUrl = shareUrl + "&wxtype=" + wxtype + "&userid=" + userid
    } else {
      shareUrl = shareUrl + "?wxtype=" + wxtype + "&userid=" + userid
    }
    console.log(shareUrl)
    if (response.data.code == 0) {
      console.log(response)
      var appId = response.data.datas.AppId;
      var Noncestr = response.data.datas.Noncestr;
      var SignAture = response.data.datas.SignAture;
      var Timestamp = response.data.datas.Timestamp;
      var ShareTitle = response.data.datas.ShareTitle;
      var ShareContent = response.data.datas.ShareContent;
      var ShareImage = response.data.datas.ShareImage;
      if (ShareTitle == "" || ShareTitle == null) {
        ShareTitle = "各尧音乐-艺术教育引领者！";
      }
      if (ShareContent == "" || ShareContent == null) {
        ShareContent = "各尧音乐，为每个学习音乐的孩子护航！";
      }
      if (ShareImage == "" || ShareImage == null) {
        ShareImage = ImageUrl + 'images/gy_logo.png';
      }
      wx.config({
        debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。    
        appId: appId, // 必填，公众号的唯一标识    
        timestamp: Timestamp, // 必填，生成签名的时间戳    
        nonceStr: Noncestr, // 必填，生成签名的随机串    
        signature: SignAture, // 必填，签名，见附录1    
        jsApiList: [
          'onMenuShareTimeline',
          'onMenuShareAppMessage',
          'hideMenuItems',
        ] // 必填，需要使用的JS接口列表，所有JS接口列表见附录2 
      })
      wx.ready(function () {
        // 在这里调用 API  
        //获取“分享到朋友圈”按钮点击状态及自定义分享内容接口  
        wx.onMenuShareTimeline({
          title: ShareTitle, // 分享标题  
          link: shareUrl, // 分享链接  
          imgUrl: ShareImage, // 分享图标  
          success: function () {
            // 用户确认分享后执行的回调函数 
            // msgAlert("您的朋友圈已经分享完成");
            Toast.success("您的朋友圈已经分享完成");
          },
          cancel: function () {
            // 用户取消分享后执行的回调函数  
          }
        });
        //获取“分享给朋友”按钮点击状态及自定义分享内容接口  
        wx.onMenuShareAppMessage({
          title: ShareTitle, // 分享标题    
          desc: ShareContent, // 分享描述  
          link: shareUrl, // 分享链接  
          imgUrl: ShareImage, // 分享图标  
          type: 'link', // 分享类型,music、video或link，不填默认为link  
          dataUrl: '', // 如果type是music或video，则要提供数据链接，默认为空  
        });
        wx.hideMenuItems({
          menuList: [
            "menuItem:share:qq",
            "menuItem:favorite",
            "menuItem:share:QZone",
            "menuItem:share:email",
            "menuItem:openWithQQBrowser",
            "menuItem:openWithSafari",
            "menuItem:share:weiboApp",
            "menuItem:share:facebook",
            "menuItem:editTag",
            "menuItem:delete",
            "menuItem:copyUrl",
            "menuItem:originPage",
            "menuItem:readMode",
            "menuItem:share:brand"
          ]
        })
      });
    }
  }).catch(function (error) {
    //输出错误信息
    // Toast.fail(error.message);
    console.log(error.message);
  })

}

/**
  * 删除URL中的指定参数
   * @param {*} url 
  * @param {*} name 
   */
function delUrlParams(url, name) {
  //根据#号拆分
  let poundArr = url.split('#')
  //？拆分
  let questionArr = []
  if (poundArr) {
    //把#接上
    poundArr.forEach((element, index) => {
      if (index > 0) {
        element = '#' + element
      }

      let tempArr = element.split('?')
      if (!tempArr) {
        return true
      }
      tempArr.forEach((item, idx) => {
        //保留问号
        if (idx > 0) {
          item = '?' + item
        }
        questionArr.push(item)
      })
    });
  } else {
    questionArr = url.split('?')
    if (questionArr) {
      questionArr.forEach((item, idx) => {
        if (idx > 0) {
          item = '?' + item
        }
      })
    }
  }

  if (!questionArr) {
    return url
  }

  //&符号的处理
  let andArr = []
  questionArr.forEach((item, index) => {
    let andIdx = item.indexOf('&')
    if (andIdx <= -1) {
      andArr.push(item)
      return true
    }

    let tempAndArr = item.split('&')
    tempAndArr.forEach((ele, idx) => {
      if (idx > 0) {
        ele = '&' + ele
      }
      andArr.push(ele)
    })
  })


  let newUrl = ''
  andArr.forEach(item => {
    let nameIndex = item.indexOf(name + '=')
    //不拼接要删除的参数
    if (nameIndex > -1) {
      //保留第一个问号
      let questionIdx = item.indexOf('?')
      if (questionIdx == 0) {
        newUrl += '?'
      }
      return true
    }
    newUrl += item
  })

  return newUrl.replace(/\?\&/g, "?")
}