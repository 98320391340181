import React from 'react'
// import { createForm } from 'rc-form';
import { createBrowserHistory } from 'history';
import { Icon, List, InputItem, DatePicker, Picker, Toast, TextareaItem } from 'antd-mobile';
import axios from "axios";
import store from '../../store';
import { Encrypt } from '../common/Encryption';
import {ImageUrl,InterfaceUrl} from '../common/PublicUrl.js';
import GetAddressData from '../common/AddressData.js';
import { WxShare } from '../common/Share.js';
import { Link } from 'react-router-dom';
import LogoImage from '../common/LogoImage';
import qs from 'qs';
const Toasts = Toast;
const history = createBrowserHistory();

class StoreLearn extends React.Component {
    constructor() {
        super()
        this.state = {
            Address: [],
            Info:'',
        }
    }
    componentDidMount() {
        var _this = this;
        window.addEventListener('popstate',(state) => {
            // 监听到返回事件，注意，只有触发了返回才会执行这个方法
            _this.props.history.push({ pathname: '/' })
        })
        var shareUrl = window.location.href
        WxShare(shareUrl);
        let wxtype=sessionStorage.getItem("wxtype");
        let param = {}
        param["Otype"] = Encrypt("store")
        param["wxtype"] = Encrypt(wxtype)
        param = qs.stringify(param)
        axios({
            method: 'post',
            url: InterfaceUrl + 'SharePiano/Interface/IGetCompanyInfo.aspx',
            data: param
        }).then(function (response) {
            console.log(response);
            if (response.data.code === 1) {
                var Info = response.data.datas.Info;
                _this.setState({
                    Info: Info
                })
            } else {

            }
        }).catch(function (error) {
           
        })
    }
    btnSubmit() {
        Toasts.loading('努力加载中...', 0, () => { }, true);
        let wxtype=sessionStorage.getItem("wxtype");
        var userid = sessionStorage.getItem("userid")
        var _this = this;
        var Name = document.getElementById("Name").value;
        var phone = document.getElementById("Phone").value;
        var address = _this.state.Address;
        var addressdetail = document.getElementById("AddressDetail").value;
        var content = document.getElementById("content").value;
        // console.log(Name + "|" + phone + "|" + address + "|" + addressdetail);
        if (Name == null || Name == "") {
            Toasts.hide();
            Toast.offline("姓名不能为空");
        } else if (phone == null || phone == "") {
            Toasts.hide();
            Toast.offline("手机号码不能为空");
        } else {
            let param = {}
            param["wxtype"] = Encrypt(wxtype)
            param["Otype"] = Encrypt("learnadd")
            param["userid"] = Encrypt(userid)
            param["name"] = Encrypt(Name)
            param["phone"] = Encrypt(phone)
            param["address"] = Encrypt(address)
            param["addressdetail"] = Encrypt(addressdetail)
            param["content"] = Encrypt(content)
            param = qs.stringify(param)
            //请求接口数据
            axios({
                //请求方式
                method: 'post',
                //请求路径
                url: InterfaceUrl + 'SharePiano/Interface/IFeedback.aspx',
                //参数
                data: param
            }).then(function (response) {
                console.log(response)
                //接收返回值
                if (response.data.code == 1) {
                    Toasts.hide();
                    Toast.success('提交成功!', 1, () => {
                        //提交成功,跳转页面
                        history.goBack()
                    });
                } else {
                    Toasts.hide();
                    Toast.offline("提交失败，请重新操作");
                }
            }).catch(function (error) {
                Toasts.hide();
                Toast.offline(error.message);
            })
        }
    }
    render() {
        return (
            <div>
                <div className="pagetop fixed">
                    <h1 className="top_left" style={{ fontSize: '14px' }}>
                        <Link to="/">
                         <img src={require('../../image/course/backIndex.png')} alt="" style={{ width: '60px' }} />
                        </Link>
                    </h1>
                    <LogoImage />
                    {/* <span className="top_right">
                        <Link to="/"><img alt="图片1" src={require('../../image/course/top_logo.png')} /></Link>
                    </span> */}
                </div>
                <div className="ptop50 pbottom50 txtright">
                    <div style={{ padding: '10px' }} dangerouslySetInnerHTML={{ __html: this.state.Info }}>
                    </div>

                    <List>
                        <InputItem
                            id="Name"
                            clear
                            placeholder="请输入姓名"
                        >姓名:</InputItem>
                        <InputItem
                            id="Phone"
                            clear
                            placeholder="请输入手机号码"
                        >手机号码:</InputItem>
                        <Picker extra="请选择"
                            data={GetAddressData}
                            value={this.state.Address}
                            onChange={v => this.setState({ Address: v })}
                            title="请选择地址"
                        >
                            <List.Item arrow="horizontal">地址:</List.Item>
                        </Picker>
                        <TextareaItem
                            id="AddressDetail"
                            title="详细地址:"
                            placeholder="请输入详细地址"
                            autoHeight
                            style={{ lineHeight: '26px' }}
                        />
                        <TextareaItem
                            id="content"
                            title="留言:"
                            placeholder="请输入留言"
                            autoHeight
                            style={{ lineHeight: '26px' }}
                        />
                    </List>
                    <div className="user_down">
                        <button type="button" onClick={this.btnSubmit.bind(this)}>
                            提交
                            </button>
                    </div>
                </div>
            </div>
        )
    }
}
export default StoreLearn;