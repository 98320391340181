import React from 'react';
import { TabBar, Toast } from 'antd-mobile';
import Course from '../course/Course';
import MyCourse from '../mycourse/MyCourse';
import My from '../my/My';
import store from '../../store';
import { Encrypt } from '../common/Encryption';
import axios from "axios";
import qs from 'qs';
import { createBrowserHistory } from 'history'
import { WxShare } from '../common/Share.js';
import { InterfaceUrl } from '../common/PublicUrl.js';
const history = createBrowserHistory();

class Bottom extends React.Component {
    constructor(props) {
        super(props);
        this.state = store.getState();
        this.handleStoreChange = this.handleStoreChange.bind(this);
        store.subscribe(this.handleStoreChange);
    }
    handleStoreChange() {
        this.setState(
            store.getState()
        )
    }
    renderContent(pageText) {
        return (
            <div style={{ backgroundColor: 'white', height: '100%', }}>
                <div style={{ paddingTop: 0 }}>{pageText}</div>
            </div>
        );
    }
    GetParameter(name) {
        var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
        var r = this.props.location.search.substr(1).match(reg);
        if (r != null) return unescape(r[2]); return null;
    }
    componentWillMount() {

        WxShare(window.location.href);
    }
    componentDidMount() {
        // window.addEventListener('popstate',(state) => {
        //     // 监听到返回事件，注意，只有触发了返回才会执行这个方法
        //     this.props.history.push({ pathname: '/' })
        // })
        let wxtype = sessionStorage.getItem("wxtype");
        let param = {}
        param["Otype"] = Encrypt("name")
        param["wxtype"] = Encrypt(wxtype)
        param = qs.stringify(param)
        axios({
            method: 'post',
            url: InterfaceUrl + 'SharePiano/Interface/IGetName.aspx',
            data: param
        }).then(function (response) {
            console.log(response);
            if (response.data.code === 1) {
                var name = response.data.datas.Name;
                if (name != null && name != "") {
                    document.title = name;
                }
            }
        }).catch(function (error) {

        })
    }
    render() {

        return (
            <div style={this.state.fullScreen ? { position: 'fixed', height: '100%', width: '100%', top: 0 } : { position: 'fixed', height: '100%', width: '100%', top: 0 }}>
                <TabBar
                    unselectedTintColor="#949494"
                    tintColor="#ff7300"
                    barTintColor="white"
                    hidden={this.state.hidden}
                >
                    <TabBar.Item
                        icon={
                            <div style={{
                                width: '22px',
                                height: '22px',
                            }}>
                                <img src={require('../../image/course/teb_course_off.png')} alt="" />
                            </div>
                        }
                        selectedIcon={
                            <div style={{
                                width: '22px',
                                height: '22px',
                            }}
                            >
                                <img src={require('../../image/course/teb_course_on.png')} alt="" />
                            </div>
                        }
                        title="课程"
                        key="course"
                        selected={this.state.selectedTab === 'redTab'}
                        onPress={() => {
                            // this.setState({
                            //     selectedTab: 'redTab',
                            // });
                            const action = {
                                type: 'change_div_color',
                                selectedTab: 'redTab',
                            }
                            store.dispatch(action);
                        }}
                        data-seed="logId1"
                    >
                        {this.renderContent(<Course selectedTab={this.state.selectedTab} />)}
                    </TabBar.Item>
                    <TabBar.Item
                        icon={
                            <div style={{
                                width: '22px',
                                height: '22px',
                            }}>
                                <img src={require('../../image/course/teb_mycourse_off.png')} alt="" />
                            </div>
                        }
                        selectedIcon={
                            <div style={{
                                width: '22px',
                                height: '22px',
                            }}
                            >
                                <img src={require('../../image/course/teb_mycourse_on.png')} alt="" />
                            </div>
                        }
                        title="我的课程"
                        key="MyCourse"
                        selected={this.state.selectedTab === 'greenTab'}
                        onPress={() => {
                            // this.setState({
                            //     selectedTab: 'greenTab',
                            // });
                            const action = {
                                type: 'change_div_color',
                                selectedTab: 'greenTab',
                            }
                            store.dispatch(action);
                        }}
                    >
                        {this.renderContent(<MyCourse selectedTab={this.state.selectedTab} />)}
                    </TabBar.Item>
                    <TabBar.Item
                        icon={
                            <div style={{
                                width: '22px',
                                height: '22px',
                            }}>
                                <img src={require('../../image/course/teb_my_off.png')} alt="" />
                            </div>
                        }
                        selectedIcon={
                            <div style={{
                                width: '22px',
                                height: '22px',
                            }}
                            >
                                <img src={require('../../image/course/teb_my_on.png')} alt="" />
                            </div>
                        }
                        title="我的"
                        key="my"
                        selected={this.state.selectedTab === 'yellowTab'}
                        onPress={() => {
                            // this.setState({
                            //     selectedTab: 'yellowTab',
                            // });
                            const action = {
                                type: 'change_div_color',
                                selectedTab: 'yellowTab',
                            }
                            store.dispatch(action);
                        }}
                    >
                        {this.renderContent(<My selectedTab={this.state.selectedTab} />)}
                    </TabBar.Item>
                </TabBar>
            </div>
        )
    }
}
export default Bottom