import React from 'react';
import {ImageUrl,InterfaceUrl} from '../common/PublicUrl.js';
import { Link } from 'react-router-dom';
import axios from "axios";
import { Encrypt } from './Encryption';
import qs from 'qs'
var dataImg = []
var axiosFunimg

class LogoImage extends React.Component{
    componentDidMount() {
        var _this = this;
        axiosFunimg = function () {
            let param = {}
            var wxtype = sessionStorage.getItem("wxtype")
            param["Otype"] = Encrypt("logostore")
            param["wxtype"]=Encrypt(wxtype)
            param = qs.stringify(param)
            axios({
                method: 'post',
                url: InterfaceUrl + 'SharePiano/Interface/IGetLogo.aspx',
                data: param
            }).then(function (response) {
                if (response.data.code == 1) {
                    var newTeam=[
                        <img src={ImageUrl+ response.data.datas.Image } alt="" />
                    ]
                    dataImg=newTeam;
                    _this.setState({
                        data: dataImg
                    })
                }else{
                    var newTeam1=[
                        <img alt="图片1" src={require('../../image/course/top_logo.png')} />
                    ]
                    dataImg=newTeam1;
                    _this.setState({
                        data: dataImg
                    })
                }   
            }).catch(function (error) {
                // Toast.fail(error);
            })
        }
        axiosFunimg();
    }
    render() {
        return (
            <span className="top_right">
            <Link to="/">
                {dataImg}
            </Link>
          </span>
        );
    }
}
export default LogoImage;