import React from 'react';
import { PullToRefresh, Toast, ListView, List } from 'antd-mobile';
import { Encrypt } from '../common/Encryption';
import axios from "axios";
import {ImageUrl,InterfaceUrl} from '../common/PublicUrl.js';
import qs from 'qs';
import { Link } from 'react-router-dom';
import { WxShare } from '../common/Share.js';
const Toasts = Toast;
const Item = List.Item;
const Brief = Item.Brief;
let page = 1;
let data = [];
class MyCommissionRecord extends React.Component {
    constructor() {
        super()
        this.state = {
            dataSource: new ListView.DataSource({
                rowHasChanged: (row1, row2) => row1 !== row2,
            }),
            isLoading: true,
            refreshing: true,
            height: document.documentElement.clientHeight,
        };
        page = 1;
        data = [];
    }
    componentDidMount() {
        window.addEventListener('popstate',(state) => {
            // 监听到返回事件，注意，只有触发了返回才会执行这个方法
            this.props.history.push({ pathname: '/' })
        })
        var shareUrl = window.location.href
        WxShare(shareUrl);
        this.axiosFun(page, 1);
    }
    axiosFun(page, type) {
        var _this = this;
        var userid = sessionStorage.getItem("userid")
        let param = {}
        param["Otype"] = Encrypt("commission")
        param["userid"] = Encrypt(userid)
        param["pageIndex"] = Encrypt(page)
        param = qs.stringify(param)
        axios({
            method: 'post',
            url: InterfaceUrl + 'SharePiano/Interface/IGetMy.aspx',
            data: param
        }).then(function (responsed) {
            console.log(responsed);
            if (responsed.data.code === 1) {
                if (type === 1) {
                    //加载更多
                    data = data.concat(responsed.data.datas)
                } else {
                    //刷新
                    data = responsed.data.datas
                }
                _this.setState({
                    dataSource: _this.state.dataSource.cloneWithRows(data),
                    isLoading: false,
                    refreshing: false
                })
            } else {
                _this.setState({
                    isLoading: false
                })
            }
        }).catch(function (error) {
            Toast.fail(error.message);
        })

    }

    renderRow(rowData) {
        return (
            <div >
                <Item
                    thumb={require('../../image/course/money.png')}
                    multipleLine
                    onClick={() => { }}
                >
                    <div className="classroom_left">
                        提成金额：{rowData.ChangeMoney}
                        <Brief>
                        购买人：{rowData.Remark1}
                        </Brief>
                        <Brief>
                        购买课程：{rowData.CourseName}
                        </Brief>
                        <Brief>
                        购买时间：{rowData.AddDate}
                        </Brief>
                        {/* <Brief style={{ width: '250px' }}>
                           当前余额：    {rowData.ChangeBalance}
                        </Brief> */}
                    </div>
                </Item>
            </div>

        )
    }
    onRefresh = () => {
        this.setState({ refreshing: true, isLoading: true });
        // simulate initial Ajax
        setTimeout(() => {
            page = 1;
            this.axiosFun(page, 2);
        }, 600);
    };
    onEndReached = (event) => {
        if (this.state.isLoading && !this.state.hasMore) {
            return;
        }
        var _this = this;
        page = page + 1;
        _this.setState({
            isLoading: true,
        })
        this.axiosFun(page, 1);

    };
    render() {
        return (
            <div>
                <ListView
                    dataSource={this.state.dataSource}
                    renderRow={(rowData) => this.renderRow(rowData)}
                    renderFooter={() => (<div style={{ padding: 30, textAlign: 'center' }}>
                        {this.state.isLoading ? 'Loading...' : '没有更多数据'}
                    </div>)}
                    pageSize={10}
                    useBodyScroll={false}
                    style={{
                        height: this.state.height,
                    }}
                    pullToRefresh={<PullToRefresh
                        refreshing={this.state.refreshing}
                        onRefresh={this.onRefresh}
                    />}
                    onEndReached={this.onEndReached}
                    onEndReachedThreshold={10}

                />
            </div>
        )
    }
}
export default MyCommissionRecord;