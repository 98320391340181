import React from 'react';
import { List, Toast } from 'antd-mobile';
import { Link } from 'react-router-dom';
import { Encrypt } from '../common/Encryption';
import axios from "axios";
import {ImageUrl,InterfaceUrl} from '../common/PublicUrl.js';
import qs from 'qs';
const Item = List.Item;
const Brief = Item.Brief;
class My extends React.Component {
    constructor() {
        super()
        this.state = {
            nickname: '',
            headimg: '',
            balance: 0
        }
    }
    componentDidMount() {
        var _this = this;
        window.addEventListener('popstate',(state) => {
            // 监听到返回事件，注意，只有触发了返回才会执行这个方法
            _this.props.history.push({ pathname: '/' })
        })
        var userid = sessionStorage.getItem("userid")
        let param = {}
        param["Otype"] = Encrypt("my")
        param["userid"] = Encrypt(userid)
        param = qs.stringify(param)
        axios({
            method: 'post',
            url: InterfaceUrl + 'SharePiano/Interface/IGetMy.aspx',
            data: param
        }).then(function (response) {
            console.log(response);
            if (response.data.code === 1) {
                var balance = response.data.datas.Remark;
                _this.setState({
                    nickname: response.data.datas.NickName,
                    headimg: response.data.datas.HeadUrl,
                    balance: balance
                })
            } else {

            }
        }).catch(function (error) {
            // Toast.fail(error.message);
        })
    }
    render() {
        return (
            <div className="my">
                <List>
                    <ul>
                        <Item multipleLine onClick={() => { }}>
                            <li className="headimg_li">
                                <div>
                                    <span className="gy_hedimg">
                                        <img className="headimgBox" src={this.state.headimg != null && this.state.headimg != "" ? this.state.headimg : require('../../image/course/t1.png')} alt="" />
                                    </span>
                                    <div className="headimg_name">
                                        <label>{this.state.nickname}</label>
                                        {/* <label className="class">大班</label> */}
                                    </div>
                                </div>
                            </li>
                        </Item>
                        {/* <Item arrow="horizontal">
                            <li className="invitae">
                                <div>
                                    <span className="leftimg">
                                        <img src={require('../../image/course/yqyl.png')} alt="" />
                                    </span>
                                    <div className="rightcontent">
                                        <label className="labelmessage">邀请有礼</label>
                                        <label className="labelmessage1">多张好礼0元领</label>
                                    </div>
                                </div>
                            </li>
                        </Item> */}
                        <Link to="/invitation/">
                            <Item
                                thumb={require('../../image/course/yqyl.png')}
                                arrow="horizontal"
                                onClick={() => { }}
                            >
                            邀请有礼</Item>
                        </Link>
                         <Link to={{
                        pathname: "/withdrawal/",
                        query: { balance: this.state.balance }
                       }}>
                        <Item
                            arrow="horizontal"
                            thumb={require('../../image/course/balance.png')}
                            extra={this.state.balance}
                            onClick={() => { }}
                        >余额</Item>
                        </Link>
                        <Link to="/myLower/">
                            <Item
                                thumb={require('../../image/course/mylower.png')}
                                arrow="horizontal"
                                onClick={() => { }}
                            >
                                我的下级</Item>
                        </Link>
                        <Link to="/myCommissionRecord/">
                            <Item
                                thumb={require('../../image/course/mycommission.png')}
                                arrow="horizontal"
                                onClick={() => { }}
                            >提成记录</Item>
                        </Link>
                        <Link to="/feedBack/">
                            <Item
                                thumb={require('../../image/course/feedback.png')}
                                arrow="horizontal"
                                onClick={() => { }}
                            >咨询与反馈</Item>
                        </Link>
                    </ul>
                </List>
            </div>
        )
    }
}
export default My;