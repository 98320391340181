import React from 'react';
import { Tabs, WhiteSpace, Card, WingBlank, PullToRefresh, Toast, ListView, List } from 'antd-mobile';
import TurnImage from '../common/TurnImage';
import LogoImage from '../common/LogoImage';
import { Encrypt } from '../common/Encryption';
import axios from "axios";
import {ImageUrl,InterfaceUrl} from '../common/PublicUrl.js';
import qs from 'qs';
import ParentClass from '../course/ParentClass';
import { Link } from 'react-router-dom';
import store from '../../store';
let page = 1;
let data = [];
var dataSj
var tabs=[{ title: '首页',Id:0 }];
class Course extends React.Component {
    constructor() {
        super()
        this.state = {
            dataSource: new ListView.DataSource({
                rowHasChanged: (row1, row2) => row1 !== row2,
            }),
            isLoading: true,
            refreshing: true,
            height: document.documentElement.clientHeight - 250,
            MenuId:0
        };
        page = 1;
        data = [];
        tabs=[{ title: '首页',Id:0 }];
    }
    componentDidMount() {
        // this.axiosFun(page, 1);
        this.axiosMenu();
        this.axiosFunC();
       var menuid= store.getState().MenuId;
       if(menuid!=null && menuid!==""){
        this.setState({
            MenuId:menuid
        })
      }
    }
    
    axiosMenu(){
        var _this = this;
        let wxtype=sessionStorage.getItem("wxtype");//"1";// 
        let param = {}
        param["Otype"] = Encrypt("menu")
        param["wxtype"] = Encrypt(wxtype)
        param = qs.stringify(param)
        axios({
            method: 'post',
            url: InterfaceUrl + 'SharePiano/Interface/IGetCourse.aspx',
            data: param
        }).then(function (response) {
            if (response.data.code === 1) {
                if (response.data.datas.length > 0) {
                    for (var i = 0; i < response.data.datas.length; i++) {
                        var newTeam = {
                            title:response.data.datas[i].Title,
                            Id:response.data.datas[i].Id
                        }
                        tabs.push(newTeam);
                    }
                }
            }
        }).catch(function (error) {
            // Toast.fail(error.message);
        })
    }
    axiosFunC() {
        var _this = this;
        let wxtype=sessionStorage.getItem("wxtype");
        let param = {}
        param["Otype"] = Encrypt("istop")
        param["wxtype"] = Encrypt(wxtype)
        param["pageIndex"] = Encrypt(1)
        param = qs.stringify(param)
        axios({
            method: 'post',
            url: InterfaceUrl + 'SharePiano/Interface/IGetCourse.aspx',
            data: param
        }).then(function (response) {
            console.log(response)
            if (response.data.code == 1) {
                var addTeam = [];
                if (response.data.datas.length > 0) {
                    for (var i = 0; i < response.data.datas.length; i++) {
                        var newTeam = [
                            <div className="course_content_body" key={i}>
                                <Link to={"/courselist/" + response.data.datas[i].Id} key={"link"+i}>
                                    <div className="course_title">
                                        <h1>{response.data.datas[i].TagName}</h1>
                                    </div>
                                    <div className="course_body">
                                        <div className="course_body_header">
                                            <span>{response.data.datas[i].TagName != null && response.data.datas[i].TagName !== "" ? [response.data.datas[i].TagName] : ""}</span>{response.data.datas[i].Title}
                                        </div>
                                        <div className="course_body_body">
                                            {response.data.datas[i].Info}
                                        </div>
                                        <div className="course_body_footer">
                                            <div className="course_body_footer-content">{response.data.datas[i].LearnAge}</div>
                                            <div className="course_body_footer-extra">
                                                <span className="old_price">￥{response.data.datas[i].OriginalPrice}</span>
                                                <span className="new_price">￥{response.data.datas[i].SellingPrice}</span>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        ];

                        addTeam.push(newTeam);
                    }
                }
                dataSj = addTeam;
                _this.setState({

                })
            }
        }).catch(function (error) {
            //输出错误信息
            //Toast.fail(error.message);
            console.log(error.message);
        })
    }
    axiosFun(page, type) {
        var _this = this;
        let param = {}
        param["Otype"] = Encrypt("istop")
        param["pageIndex"] = Encrypt(page)
        param = qs.stringify(param)
        axios({
            method: 'post',
            url: InterfaceUrl + 'SharePiano/Interface/IGetCourse.aspx',
            data: param
        }).then(function (responsed) {
            console.log(responsed);
            if (responsed.data.code === 1) {
                if (type === 1) {
                    //加载更多
                    data = data.concat(responsed.data.datas)
                } else {
                    //刷新
                    data = responsed.data.datas
                }
                _this.setState({
                    dataSource: _this.state.dataSource.cloneWithRows(data),
                    isLoading: false,
                    refreshing: false
                })
            } else {
                _this.setState({
                    isLoading: false
                })
            }
        }).catch(function (error) {
            // Toast.fail(error.message);
        })

    }

    renderRow(rowData) {
        return (
            <div className="course_content_body">
                <Link to={"/courselist/" + rowData.Id}>
                    <div className="course_title">
                        <h1>{rowData.TagName}</h1>
                    </div>
                    <div className="course_body">
                        <div className="course_body_header">
                            <span>{rowData.TagName != null && rowData.TagName !== "" ? [rowData.TagName] : ""}</span>{rowData.Title}
                        </div>
                        <div className="course_body_body">
                            {rowData.Info}
                        </div>
                        <div className="course_body_footer">
                            <div className="course_body_footer-content">{rowData.LearnAge}</div>
                            <div className="course_body_footer-extra">
                                <span className="old_price">￥{rowData.OriginalPrice}</span>
                                <span className="new_price">￥{rowData.SellingPrice}</span>
                            </div>
                        </div>
                    </div>
                </Link>
            </div>
        )
    }
    onRefresh = () => {
        this.setState({ refreshing: true, isLoading: true });
        // simulate initial Ajax
        setTimeout(() => {
            page = 1;
            this.axiosFun(page, 2);
        }, 600);
    };
    onEndReached = (event) => {
        if (this.state.isLoading && !this.state.hasMore) {
            return;
        }
        var _this = this;
        page = page + 1;
        _this.setState({
            isLoading: true,
        })
        this.axiosFun(page, 1);

    };
    renderContent(pageText) {
        return (
            <div style={{ backgroundColor: 'white', height: '100%', }}>
                <div style={{ paddingTop: 0 }}>{pageText}</div>
            </div>
        );
    }
    //改变tab，返回回来还是当前tab
    ChangeClick = (e, index) => {
       store.getState().courseIndex = index;
       store.getState().MenuId=e.Id;
        this.setState({
            MenuId:e.Id
        })
    }
  
    render() {
       return (
            <div>
                <div className="pagetop">
                    <h1 className="top_left">课程</h1>
                    <LogoImage />
                    {/* <span className="top_right">
                        <img alt="图片1" src={require('../../image/course/top_logo.png')} />
                    </span> */}
                </div>
                <div className="course_tab">
                    <WhiteSpace />
                    <Tabs tabs={tabs} prerenderingSiblingsNumber={0} initialPage={store.getState().courseIndex} tabBarPosition="top" animated={false} swipeable={false} useOnPan={false} onChange={this.ChangeClick}>
   
                        <div style={{ display: 'block', alignItems: 'center', justifyContent: 'center', backgroundColor: '#fff' }}>
                            <div className="turn-img">
                                <TurnImage />
                            </div>
                            <ul className="tab_two">
                                <Link to="/companyInfo/">
                                    <li className="col-xs-5 modules">
                                        <span><img src={require('../../image/course/ljgy.png')} alt="" /></span>
                                        <span>了解门店</span>
                                    </li>
                                </Link>
                                <Link to="/storeLearn/">
                                    <li className="col-xs-5 modules ml10">
                                        <span><img src={require('../../image/course/address.png')} alt="" /></span>
                                        <span>线下门店学习</span>
                                    </li>
                                </Link>
                            </ul>
                            <div className="course_content">
                                {dataSj}
                            </div>
                        </div>

                        <div style={{ display: 'block', alignItems: 'center', justifyContent: 'center', backgroundColor: '#fff' }}>
                            <div className="course_content">
                                {this.renderContent(<ParentClass MenuId={this.state.MenuId} />)}
                            </div>
                        </div>
                        <div style={{ display: 'block', alignItems: 'center', justifyContent: 'center', backgroundColor: '#fff' }}>
                            <div className="course_content">
                                {this.renderContent(<ParentClass MenuId={this.state.MenuId} />)}
                            </div>
                        </div>
                        <div style={{ display: 'block', alignItems: 'center', justifyContent: 'center', backgroundColor: '#fff' }}>
                            <div className="course_content">
                                {this.renderContent(<ParentClass MenuId={this.state.MenuId} />)}
                            </div>
                        </div>
                        <div style={{ display: 'block', alignItems: 'center', justifyContent: 'center', backgroundColor: '#fff' }}>
                            {this.renderContent(<ParentClass MenuId={this.state.MenuId} />)}
                        </div>
                        <div style={{ display: 'block', alignItems: 'center', justifyContent: 'center', backgroundColor: '#fff' }}>
                            {this.renderContent(<ParentClass MenuId={this.state.MenuId} />)}
                        </div>
                    </Tabs>
                    <WhiteSpace />
                </div>
            </div>
        )
    }
}
export default Course