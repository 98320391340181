import React from 'react';
var CoverImage = "";
export default class VideoBuy extends React.Component {
    // constructor() {
    //     super()
    //     this.state = {
    //         CoverImage: ''
    //     }
    // }
    componentDidUpdate() {
        CoverImage = this.props.CoverImage;
        console.log(CoverImage)
    }
    render() {
        return (
            // <div className="video-content" style={{ height:'200px', top: '0px' }}>
                <div className="video-content__video-wrapper video-content--fixed" style={{ width: '100%',height:'200px',  top: '50px' }}>

                    <div className="video-cover video-cover--overlay" style={{ background: CoverImage != null && CoverImage !== "" ? 'url(' + CoverImage + ')  center center / cover' : "" }}>
                        <div className="video-cover__icon-lock imgWrap" style={{ width: '20px', height: '23px' }}>
                            <img alt="" src="http://work.geyaogf.com/SharePiano/image/lock.svg" className="imgWrap__img" style={{ objectFit: 'cover' }} />

                        </div>
                        <div className="video-cover__tip"><span>购买后点击专栏目录可观看</span></div>
                    </div>
                </div>
            // </div>
        )
    }
}