import React, { Component } from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Bottom from './content/common/Bottom';//共用底部页面
import Course from './content/course/Course';
import CourseList from './content/course/CourseList';
import CourseVideo from './content/course/CourseVideo'; //视频播放
import MyLower from './content/my/MyLower';//我的下级
import MyCommissionRecord from './content/my/MyCommissionRecord';//我的提成记录
import CompanyInfo from './content/course/CompanyInfo';//公司介绍
import Invitation from './content/my/Invitation';//邀请有礼
import ShareQrCode from './content/my/ShareQrCode';
import StoreLearn from './content/course/StoreLearn';//线下门店学习
import FeedBack from './content/my/Feedback';//问题反馈
import Withdrawal from './content/my/Withdrawal';//提现页面
import WithdrawalRecord from './content/my/WithdrawalRecord';//提现记录
import { isWeixinBrowser } from './content/common/PublicFunction';
import { isOpenId } from './content/common/PublicFunction';
import ErrorPage from './content/error/Error';//错误页面
import BrowserError from './content/error/BrowserError';//错误页面
import WeixinJSBridge from 'weixin-js-sdk';
class App extends Component {

  render() {
    var isWeixin =isWeixinBrowser();  //true;//
    isOpenId();
    return (
      <Router>
        <div className="bodys" style={{ width: '100%', height: '100%' }}>
          {isWeixin === true ?
            <Switch>
              <Route exact path="/" component={Bottom} />
              {/* 课程 */}
              <Route path="/course" component={Course} />
              <Route path="/courselist/:id" component={CourseList} />
              {/* 视频播放 */}
              <Route path="/courseVideo" component={CourseVideo} />
              {/* 我的下级 */}
              <Route path="/myLower" component={MyLower} />
              {/* 我的提成记录 */}
              <Route path="/myCommissionRecord" component={MyCommissionRecord} />
              {/* 公司介绍 */}
              <Route path="/companyInfo" component={CompanyInfo} />
              {/* 邀请有礼 */}
              <Route path="/invitation" component={Invitation} />
              {/* 生成二维码 */}
              <Route path="/shareQrCode" component={ShareQrCode} />
              {/* 线下门店学习 */}
              <Route path="/storeLearn" component={StoreLearn} />
              {/* 问题反馈 */}
              <Route path="/feedBack" component={FeedBack} />
              {/* 提现页面 */}
              <Route path="/withdrawal" component={Withdrawal} />
              {/* 提现记录 */}
              <Route path="/withdrawalRecord" component={WithdrawalRecord} />
              <Route component={ErrorPage} />
            </Switch>
            :
            <Switch>
              <Route exact path="/" component={BrowserError} />
            </Switch>}
        </div>
      </Router>
    )
  }
}

export default App;
