import React from 'react';
import { Link } from 'react-router-dom';
import { PullToRefresh, Toast, ListView, List } from 'antd-mobile';
import { Encrypt } from '../common/Encryption';
import axios from "axios";
import {InterfaceUrl} from '../common/PublicUrl.js';
import qs from 'qs';

let page = 1;
let data = [];
class MyCourse extends React.Component {
    constructor() {
        super()
        this.state = {
            dataSource: new ListView.DataSource({
                rowHasChanged: (row1, row2) => row1 !== row2,
            }),
            isLoading: true,
            refreshing: true,
            height: document.documentElement.clientHeight,
        };
        page = 1;
        data = [];
    }
    componentDidMount() {
        window.addEventListener('popstate',(state) => {
            // 监听到返回事件，注意，只有触发了返回才会执行这个方法
            this.props.history.push({ pathname: '/' })
        })
        this.axiosFun(page, 1);
    }
    axiosFun(page, type) {
        var userid = sessionStorage.getItem("userid") 
        console.log(userid)
        var _this = this;
        let param = {}
        param["Otype"] = Encrypt("mycourse")
        param["userid"] = Encrypt(userid)
        param["pageIndex"] = Encrypt(page)
        param = qs.stringify(param)
        axios({
            method: 'post',
            url: InterfaceUrl + 'SharePiano/Interface/IGetCourse.aspx',
            data: param
        }).then(function (responsed) {
            if (responsed.data.code === 1) {
                if (type === 1) {
                    //加载更多
                    data = data.concat(responsed.data.datas)
                } else {
                    //刷新
                    data = responsed.data.datas
                }
                _this.setState({
                    dataSource: _this.state.dataSource.cloneWithRows(data),
                    isLoading: false,
                    refreshing: false
                })
            } else {
                _this.setState({
                    isLoading: false
                })
            }
        }).catch(function (error) {
            // Toast.fail(error.message);
            console.log(error.message)
        })

    }

    renderRow(rowData) {
        return (
            <div className="course_content_body">
                <Link to={"/courselist/"+rowData.Id}>
                    <div className="course_body">
                        <div className="course_body_header">
                            <span>{rowData.TagName != null && rowData.TagName !== "" ? [rowData.TagName] : ""}</span>{rowData.Title}
                        </div>
                        <div className="course_body_body">
                            {rowData.Info}
                        </div>
                        <div className="course_body_footer">
                            <div className="course_body_footer-content">{rowData.LearnAge}</div>
                            <div className="course_body_footer-extra">
                                <span className="old_price">￥{rowData.OriginalPrice}</span>
                                <span className="new_price">￥{rowData.SellingPrice}</span>
                            </div>
                        </div>
                    </div>
                </Link>
            </div>
        )
    }
    onRefresh = () => {
        this.setState({ refreshing: true, isLoading: true });
        // simulate initial Ajax
        setTimeout(() => {
            page = 1;
            this.axiosFun(page, 2);
        }, 600);
    };
    onEndReached = (event) => {
        if (this.state.isLoading && !this.state.hasMore) {
            return;
        }
        var _this = this;
        page = page + 1;
        _this.setState({
            isLoading: true,
        })
        this.axiosFun(page, 1);

    };
    renderContent(pageText) {
        return (
            <div style={{ backgroundColor: 'white', height: '100%', }}>
                <div style={{ paddingTop: 0 }}>{pageText}</div>
            </div>
        );
    }
    
    render() {
        return (
            <div>
                <div className="pagetop">
                    <h1 className="top_left">我的课程</h1>
                </div>
                <div className="course_tab ptop20">
                    <div className="menu_title">
                       
                    </div>
                    <div className="course_content">
                    <ListView
                                    dataSource={this.state.dataSource}
                                    renderRow={(rowData) => this.renderRow(rowData)}
                                    renderFooter={() => (<div style={{ padding: 30, textAlign: 'center' }}>
                                        {this.state.isLoading ? 'Loading...' : '没有更多数据'}
                                    </div>)}
                                    pageSize={10}
                                    useBodyScroll={false}
                                    style={{
                                        height: this.state.height,
                                    }}
                                    pullToRefresh={<PullToRefresh
                                        refreshing={this.state.refreshing}
                                        onRefresh={this.onRefresh}
                                    />}
                                    onEndReached={this.onEndReached}
                                    onEndReachedThreshold={10}

                                />
                            {/* <div className="course_content_body">
                                <Link to="/courselist">

                                    <div className="course_body">
                                        <div className="course_body_header">
                                            <span>[新用户专享]</span>家长课堂，学习音乐中的疑问班
                                   </div>
                                        <div className="course_body_body">
                                            <div>您的孩子准备学琴或已经开始学琴</div>
                                            <div>请务必认真听完课程</div>
                                        </div>
                                        <div className="course_body_footer">
                                            <div class="course_body_footer-content">学习年龄：3-12岁</div>
                                            <div class="course_body_footer-extra">
                                                <span class="old_price">￥299</span>
                                                <span class="new_price">￥49</span>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            <div className="course_content_body"> 
                                <Link to="/courselist">

                                    <div className="course_body">
                                        <div className="course_body_header">
                                            <label>双师系统班</label>幼儿园大班升一年级数学暑期系统班
                                   </div>
                                        <div className="course_body_body">
                                            <div>您的孩子准备学琴或已经开始学琴</div>
                                            <div>请务必认真听完课程</div>
                                        </div>
                                        <div className="course_body_footer">
                                            <div class="course_body_footer-content">学习年龄：3-12岁</div>
                                            <div class="course_body_footer-extra">
                                                <span class="old_price">￥299</span>
                                                <span class="new_price">￥49</span>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </div>*/}
                        </div>
                </div>
            </div>
        )
    }
}
export default MyCourse;