import React from 'react';
import { Toast } from 'antd-mobile';
import Swiper from 'swiper/js/swiper.js';
import 'swiper/css/swiper.min.css'
import {ImageUrl,InterfaceUrl} from '../common/PublicUrl.js';
import store from '../../store';
import axios from "axios";
import { Encrypt } from './Encryption';
import qs from 'qs'

var dataImg = []
var axiosFunimg
class TurnImage extends React.Component{
    componentDidMount() {
        function mySwiper() {
            var mySwiper = new Swiper('.swiper-container', {
                direction: 'horizontal',
                loop: true,
                pagination: {
                    el: '.swiper-paginations',
                    type: 'fraction',
                    clickable: true,    // 允许点击跳转
                },
                autoplay: {      //自动播放，注意：直接给autoplay:true的话，在点击之后不能再自动播放了
                    delay: 2500,
                    disableOnInteraction: false,    //户操作swiper之后，是否禁止autoplay。默认为true：停止。
                },

            })
        }
        mySwiper();
        var _this = this;
        var response
        function addTeamimg() {
            var addTeamimg = [];
            for (let i = 0; i < response.data.datas.length; i++) {
                var newTeam = [
                    <div className='swiper-slide' key={"swiper" + i} ><img src={ImageUrl+ response.data.datas[i].Image } /></div>
                ]
                addTeamimg.push(newTeam);
            }
            dataImg = addTeamimg;
            _this.setState({
                data: dataImg
            })
        }
        axiosFunimg = function () {
            let param = {}
            var wxtype = sessionStorage.getItem("wxtype")
            param["Otype"] = Encrypt("logo")
            param["wxtype"]=Encrypt(wxtype)
            param = qs.stringify(param)
            axios({
                method: 'post',
                url: InterfaceUrl + 'SharePiano/Interface/IGetLogo.aspx',
                data: param
            }).then(function (responsed) {
                console.log(responsed)
                if (responsed.data.code == 1) {
                    response = responsed;
                    addTeamimg();
                    mySwiper();
                }
            }).catch(function (error) {
                // Toast.fail(error);
            })
        }
        axiosFunimg();
    }
    render() {
        return (
            <div className='banner'>
                <div className='swiper-container'>
                    <div className='swiper-wrapper'>
                    {dataImg}
                        {/* <div className='swiper-slide'><img src={require('../../image/banner1.png')} /></div>
                        <div className='swiper-slide'><img src={require('../../image/banner1.png')} /></div>
                        <div className='swiper-slide'><img src={require('../../image/banner1.png')} /></div> */}
                    </div>
                    {/* <div className="swiper-paginations"></div> */}
                </div>
            </div>
        );
    }
}
export default TurnImage;