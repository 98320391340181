import React from 'react';
import QRCode from 'qrcode.react';
import { Toast } from 'antd-mobile';
import $ from 'jquery';
import html2canvas from 'html2canvas';
import { Encrypt } from '../common/Encryption';
import axios from "axios";
import { ImageUrl, InterfaceUrl } from '../common/PublicUrl.js';
import qs from 'qs';
const Toasts = Toast;
var dataSj
class ShareQrCode extends React.Component {
    constructor() {
        super()
        this.state = {
            qrUrl: InterfaceUrl + "?userid=1"
        }
    }
    componentDidMount() {
        Toasts.loading('努力加载中...', 0, null, true);
        var _this = this;
        var userid = sessionStorage.getItem("userid")
        let wxtype = sessionStorage.getItem("wxtype")
        if (userid != null && userid != "") {
            let param = {}
            param["Otype"] = Encrypt("qrcodebj")
            param["wxtype"] = Encrypt(wxtype)
            param["userid"] = Encrypt(userid)
            param = qs.stringify(param)
            axios({
                method: 'post',
                url: InterfaceUrl + 'SharePiano/Interface/IGetMy.aspx',
                data: param
            }).then(function (response) {
                if (response.data.code === 1) {
                    var nickname = response.data.datas.NickName
                    var headimg = response.data.datas.HeadUrl
                    var imgburl = response.data.datas.Remark
                    var wxtype = response.data.datas.Type
                    var url = InterfaceUrl + "/JMOpenId.aspx?wxtype=" + wxtype + "&userid=" + userid;
                    var addTeam = [
                        <div className="content" key="qrcontent">
                            <section className="swiper-container full swiper-container-horizontal" style={{ height: "auto" }}>
                                <div className="swiper-slide swiper-slide-next" style={{ visibility: 'visible', width: '100%' }}>
                                    <div id="dowmimg" style={{ margin: '1rem', position: 'relative' }}>
                                        <img style={{ width: '100%', height: 'auto' }} className="swiper-lazy swiper-lazy-loaded" src={imgburl} alt="" />
                                        <div style={{ position: 'absolute', left: '1rem', right: '1rem', bottom: '4rem', textAlign: 'center' }}>
                                            <div style={{ display: 'inline-block', width: '100%', textAlign: 'center' }}>
                                                <p>
                                                    <QRCode
                                                        value={url}
                                                        size={100}
                                                        fgColor="#000000"
                                                    />
                                                </p>
                                                <p>
                                                    <img src={headimg} style={{ height: '20px', width: '20px', radius: '10px', verticalAlign: 'middle' }} alt="" />
                                                    <small style={{ shadow: '0 1px 0 rgba(0, 0, 0, 0.5)', color: '#fff', fontSize: '0.6rem' }}>{nickname}</small>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <p className="center qrcode">
                                        <button className="small inline" onClick={_this.downloadImage.bind(this)}>
                                            {/* <i className="fa fa-fw fa-image"></i>*/} 生成海报
                                        </button>
                                    </p>
                                </div>
                            </section>
                            <section className="hide">
                                <div id="layui_m_layer" className="layui-m-layer layui-m-layer0 " index="0">
                                    <div className="layui-m-layershade"></div>
                                    <div className="layui-m-layermain">
                                        <div className="layui-m-layersection">
                                            <div className="layui-m-layerchild  layui-m-anim-scale">
                                                <div className="layui-m-layercont">
                                                    <div style={{ position: 'absolute', top: '0.3rem', right: '0.3rem' }} onClick={_this.CloseLayer.bind(this)}>
                                                        <img src={require("../../image/close.png")} style={{ width: '15px', height: '15px' }} alt="" />
                                                    </div>
                                                    <div id="postPhoto"></div>
                                                    <p className="center"><small>长按图片保存到相册</small></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    ]
                    Toasts.hide();
                    dataSj = addTeam;
                    _this.setState({})
                }
            }).catch(function (error) {
                Toasts.hide();
                Toast.fail(error.message);
            })
        } else {
            Toasts.hide();
        }
    }
    downloadImage() {
        Toasts.loading('努力加载中...', 0, null, true);
        try {
            html2canvas(document.getElementById('dowmimg'), { allowTaint: false,useCORS: true }).then(function (canvas) {
                $("#postPhoto").html("");
                $("<img src='" + canvas.toDataURL() + "' width='100%' />").prependTo($("#postPhoto"));
                $(".hide").show();
                Toasts.hide();
            });
        } catch (e) {
            Toasts.hide();
            Toasts.fail("生成失败！");
            console.log(e);
        }
    }
    CloseLayer() {
        $(".hide").hide();
    }
    render() {
        return (
            <div>
                {dataSj}
            </div>
        )
    }
}
export default ShareQrCode;
