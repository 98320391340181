import WeixinJSBridge from 'weixin-js-sdk';
import { Toast } from 'antd-mobile';
import axios from "axios";
import qs from 'qs';
import store from '../../store';
import {InterfaceUrl} from '../common/PublicUrl.js';
export function Pay(courseId,name,phone) {
    var openid = sessionStorage.getItem("openid")
    console.log("opneid" + openid)
    let param = {}
    param["id"] = courseId
    param["openid"] = openid
    param["name"]=name
    param["phone"]=phone
    param = qs.stringify(param)
    axios({
        method: 'post',
        url: InterfaceUrl+'SharePiano/Interface/getPay.aspx',
        data: param
    }).then(function (response) {
        console.log(response)
        if (response.data.code == 0) {
            var appId = response.data.datas.AppId;
            var Timestamp = response.data.datas.Timestamp;
            var Noncestr = response.data.datas.Noncestr;
            var paySign = response.data.datas.paySign;
            var Package =response.data.datas.Package;
            //公众号支付
            window.WeixinJSBridge.invoke('getBrandWCPayRequest', {
                "appId": appId, //公众号名称，由商户传入
                "timeStamp": Timestamp, //时间戳
                "nonceStr": Noncestr, //随机串
                "package": Package, //扩展包
                "signType": "MD5", //微信签名方式:MD5
                "paySign": paySign //微信签名
            }, function (res) {
                var str = res.err_msg;
                console.log(res)
                if (str == "get_brand_wcpay_request:ok") {
                    // mui.alert("支付完成！请确定安装完成", '海优家', function () {
                    //     location.href = "../HYJWX/Installation.aspx";
                    // });
                    Toast.success('支付成功!', 1, () => {
                        //提交成功,跳转页面
                        // history.goBack()
                        // this.props.history.push({ pathname: '/course' })
                    });
                    Toast.success("支付成功");
                    //支付成功调用后台方法查询支付情况
                } else {
                    // mui.alert(res, '海优家', function () {
                    //     location.href = "../HYJWX/Experience.aspx";
                    // });
                    Toast.fail("支付失败")
                }
                // 使用以上方式判断前端返回,微信团队郑重提示：res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
                //因此微信团队建议，当收到ok返回时，向商户后台询问是否收到交易成功的通知，若收到通知，前端展示交易成功的界面；若此时未收到通知，商户后台主动调用查询订单接口，查询订单的当前状态，并反馈给前端展示相应的界面。
            });
        } else {
            Toast.fail("支付失败")
        }
    })

}

//提现
export function WithdrawalApply(money) {
    var openid = sessionStorage.getItem("openid")
    console.log("opneid" + openid)
    let param = {}
    param["money"] = money
    param["openid"] = openid
    param = qs.stringify(param)
    axios({
        method: 'post',
        url: InterfaceUrl+'SharePiano/Interface/WithdrawalPay.aspx',
        data: param
    }).then(function (response) {
        if (response.data.code == 1) {
            Toast.success("提现成功")
        } else {
            var msg = response.data.msg;
            if (msg != null && msg != "") {
                Toast.fail(response.data.msg)
            } else {
                Toast.fail("提现失败");
            }
        }
    })
}